.wrapper-calc {
    max-width: 420px;
    min-height: 46vh;
    margin: 0 auto;
}

button {
    font-size: 2rem;
    cursor: pointer;
}

.modifiers button {
    background-color: #54C2B7;
    color: #fff;
    border-radius: 5px;
}

.operations button {
    background-color: #008779;
    border-radius: 5px;
    color: #fff;
}

.digits button {
    background-color: #CFCFCF;
    border-radius: 5px;

}

/* All things Grid, Flexbox, Box Alignment */
.wrapper-calc,
.subgrid {
    display: grid;
}

.wrapper-calc {
    grid-template-areas:
        "total total total total"
        "modif modif modif oper"
        "digit digit digit oper"
        "digit digit digit oper"
        "digit digit digit oper"
        "digit digit digit oper";
    grid-auto-columns: 1fr;
}

.show-input {
    grid-area: total;
}

.modifiers {
    grid-area: modif;
}

.operations {
    grid-area: oper;
}

.digits {
    grid-area: digit;
}

.show-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
}

.modifiers {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

.digits {
    display: flex;
    flex-wrap: wrap;
}

.digits button {
    flex: 1 0 26%;
}