.card-container {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
  margin: 15px;
}

.card-container:hover {
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
}

.center {
  margin-left: auto;
  margin-right: auto;
}
