.accordion-section {
    display: flex;
    flex-direction: column;
}

.accordion-title {
    background-color: #54c2b7;
    color: #fff;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
}

.accordion-title:hover,
.active {
    background-color: #54c2b7;
}

.accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

.rotate {
    transform: rotate(90deg);
}

.accordion-content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

.accordion-text {
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
}