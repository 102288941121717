@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
  z-index: 2;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.schedule {
  margin: 20px;
  width: 976px;
}

img.thumbnail::after {
  content: "Read this -";
  background-color: yellow;
  color: red;
  font-weight: bold;
}

.materi-dan-pembahasan,
.materi,
.contoh-soal,
.kuis-mandiri,
.try-out,
.forum-diskusi,
.bimbel,
.to-application {
  display: flex;
  margin-left: 260px;
}
.card-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
}

.card-container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.force-overflow {
  /* overflow-x: hidden; */
  overflow-y: hidden;
  max-height: 450px;
}
@media (max-width: 480px) {
  .web {
    display: none;
  }
  .mobile {
    display: flex;
  }
}
/* @media (min-width: 1000px) {
  .mobile {
    display: none;
  }
} */
@media (min-width: 1025px) and (max-width: 1280px) {
  .mobile {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .mobile {
    display: none;
  }
}
.circleBorder {
  background-image: url("../src/assets/img/FACEBOOK.png");

  width: 50px;
  height: 50px;
}

.sr-only {
  display: none;
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .mobile {
    display: none;
  }
}
.large-2::-webkit-scrollbar-track {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  /* padding: 2px 0; */
  background-color: #c4c4c4;
}

.large-2::-webkit-scrollbar {
  width: 7px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ec9119;
}

/* .paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  color: rgb(59, 62, 226);
  justify-content: center;
} */

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 33%;
  font-size: 0.8rem;
  border: 1px solid #54c2b7;
  color: #2b2eff;
}

.paginationBttns a:hover {
  background-color: #54c2b7;
}

.paginationActive a {
  background-color: #54c2b7;
}

.sidebar {
  min-width: 300px;
  max-width: 280px;
  background: #334c48;
  color: #fff;
  margin-left: -300px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
  z-index: 100;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
}

.sidebar-header {
  background: #54c2b7;
  z-index: 100;
  position: relative;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #2a4540;
}

.nav-item:hover {
  background: #036452;
  border-radius: 5px;
}

.nav-item-submenu:hover {
  background: #036452;
  border-radius: 5px;
}

.items-menu {
  color: #ffffff;
  background: #2a4540;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #036452;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #155a4f;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.spinner-2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid;
  border-color: lightblue;
  border-right-color: orange;
  animation: s2 1s infinite linear;
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}

#loadingDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
