.di-aplikasi {
  margin-left: 28px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 46% 46% 46% 46%;
  border: 1.5px solid #fff;
  background-color: transparent;
  padding: 8px 18px;
  cursor: pointer;
  text-decoration: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.menu-title {
  color: #fff;
}
