.btnCustom {
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    margin-left: 3rem !important;
    border-color: #54c2b7 !important;
    z-index: 10 !important;
    background-color: #54c2b7 !important;
    border-color: #54c2b7 !important;
}

.btnWA {
    border-radius: 50% !important;
    background: #28D369 !important;
    margin-left: 3rem !important;
    border-color: #54c2b7 !important;
    z-index: 10 !important;
}

.done {
    background-color: #0072BF;
    width: 15px;
    height: 15px;
    display: inline-block;
}

.on-progress {
    background-color: #F9AE42;
    width: 15px;
    height: 15px;
    display: inline-block;
}

.not-yet {
    background-color: #BD2931;
    width: 15px;
    height: 15px;
    display: inline-block;
}

label.radio {
    display: block;
    text-align: left;
    padding: 15px;
    margin-bottom: 5px;
    font-size: 20px;
}

label.radio+.radio {
    margin-left: 0;
}

label.radio input {
    margin-right: 10px;
}

.cardShadow {
    box-shadow: 10px 10px 15px #B8B8B8;
}

.btnDanger {
    background-color: red !important;
    border-color: red !important;
}

.btnDanger:hover {
    background-color: red !important;
}

.modal {
    overflow: hidden !important;
}

.legenda {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
}